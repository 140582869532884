<template>
  <ion-page>
    <app-header back="login" title="홈"/>
    <ion-content :fullscreen="true">

      <div id="container">
        
        <ion-list-header class="list_header2" lines="full" >
          &nbsp; 
          <ion-badge v-if="auth.auth.includes('1')||auth.auth.includes('2')||auth.auth.includes('3')">본사</ion-badge>
          <ion-badge v-if="auth.auth.includes('4')" color ="success">거래처</ion-badge>
          <ion-badge v-if="auth.auth.includes('5')" color ="danger">운송사</ion-badge>
          <ion-badge v-if="auth.auth.includes('6')" color ="warning">거래처&운송사</ion-badge>
          &nbsp;{{auth.name}} 님 </ion-list-header>
        <menu-item v-if="['4','5','6'].includes(auth.auth[0])" link="/user" title="개인정보변경"/>
        <menu-item v-if="auth.auth.includes('1')||auth.auth.includes('3')" link="/manage" title="사용자 1차승인" :newCnt="newCnt.apprCnt"/>
        <menu-item v-if="auth.auth.includes('2')||auth.auth.includes('3')" link="/finalapro" title="사용자 2차승인" :newCnt="newCnt.finalCnt"/>
        <ion-list>
          <ion-list-header class="list_header" lines="full" > 메뉴 </ion-list-header>
          <!-- <menu-item v-if="auth.auth.includes('1')" link="/assignment" title="출하신규배정" />
          <menu-item v-if="auth.auth.includes('1')" link="/overtime" title="출하배정현황" /> -->
          <menu-item v-if="['1','2','3'].includes(auth.auth[0])" link="" @click ="chkErp('출하신규배정')" title="출하신규배정" />
          <menu-item v-if="['1','2','3'].includes(auth.auth[0])" link="" @click ="chkErp('출하배정현황')" title="출하배정현황" />
          <menu-item v-if="['1','2','3','4','6'].includes(auth.auth[0])" link="" @click ="chkErp('실시간출하현황')" title="실시간출하현황" />
          <menu-item v-if="['1','2','3'].includes(auth.auth[0])" link="" @click ="chkErp('거래처별출하집계')" title="거래처별출하집계" />
          <menu-item v-if="['1','2','3'].includes(auth.auth[0])" link="" @click ="chkErp('출하현황집계')" title="출하현황집계" />
          <menu-item v-if="['1','2','3'].includes(auth.auth[0])"  link="" @click ="chkErp('영업속보')" title="영업속보" />         
          <menu-item v-if="['1','2','3','4','6'].includes(auth.auth[0])" link="/chulhaday" title="거래처 일별 출하현황" />
          <menu-item v-if="['1','2','3','4','6'].includes(auth.auth[0])" link="/chulhamonth" title="거래처 월별 출하현황" />
          <menu-item
          v-if="['1','2','3','4','6'].includes(auth.auth[0])"
            link="/close"
            title="거래처마감확인"
          />
          <menu-item v-if="['1','2','3','5','6'].includes(auth.auth[0])" link="/rtts" title="실시간운반현황" />
          <menu-item v-if="['1','2','3','5','6'].includes(auth.auth[0])" link="/crdmday" title="운송사 일별 운반현황" />
          <menu-item v-if="['1','2','3','5','6'].includes(auth.auth[0])" link="/crdmmonth" title="운송사 월별 운반현황" />
          <!-- <menu-item v-if="['1','3'].includes(auth.auth[0])" link="/crdm" title="운송사 일/월 운반현황" /> -->
          <menu-item v-if="['1','2','3','5','6'].includes(auth.auth[0])" link="/crcs" title="운송사 운반비현황" />       
        </ion-list>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {

  IonContent,
  IonPage,
  IonList,
  IonListHeader,
  onIonViewWillEnter,
  modalController,
  IonBadge
} from '@ionic/vue';
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { caretForward,bookmark } from 'ionicons/icons';
import { useHome } from '@/api/services/home';
import Modal1  from '@/views/modal/UserModal3.vue'
// import { alert } from '@/utils/alert';
// import { toast } from '@/utils/toast';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonPage,
    IonList,
    IonListHeader,
    IonBadge
  },
  setup() {
    const { newCnt, fetchData,chkAppr } = useHome();
    const store = useStore();
    const auth = computed(() => store.getters['auth/getData']);

    const openModalPassword = async () => {
      const modal = await modalController.create({
        component: Modal1,
        cssClass: 'my-modal-class3',
        componentProps:{
          title: 'pwd'
        } ,
        backdropDismiss:false
        //Modal is name of the component to render inside ionic modal
      });
      return modal.present();
    }; 
    onIonViewWillEnter(async () => {
      await fetchData();
      sessionStorage.removeItem('itnmlist');
      if(newCnt.value.credentialsExpired < 0){
        await openModalPassword();
      }
    });
    async function chkErp(chk: any){
      await chkAppr(chk);
    }
    return {
      caretForward,
      bookmark,
      auth,
      newCnt,
      openModalPassword,
      chkErp
    };
  }
});
</script>

<style scoped>
#container {
  padding: 1em 0.5em;
  margin: 0 auto;
  max-width: 800px;
}
.list_header {
  padding-left: 24px;
  font-size: 1.3em;
  font-weight: bold;
}
.list_header2 {
  padding-left: 0px;
  font-size: 1.3em;
  font-weight: bold;
}
ion-badge {
  vertical-align: middle;
  font-size: 0.7em;
}
ion-item {
  --min-height: 55px;
  --padding-start: 1.5em;
}
/* ion-card {
  border-radius: 30px;
  margin-bottom: 1em;
}
ion-card-title {
  font-size: 1.2em;
} */
ion-icon {
  font-size: 1.8em;
  margin: 0 0 0 5px;
  color: var(--ion-color-primary);
}
.red{
  color: red;
}
.green{
  color: green
}
.blue{
  color: blue;
}
</style>